import { PatternsIllustrations } from './patternsIllustrations'
import Slideshow from '../../components/Slideshow/slideshow'
import Footer from '../../components/Footer/footer'

function Patterns() {
  return window.innerWidth > 768 ? (
    <>
      <Slideshow pictures={PatternsIllustrations} parentClass="gallerySmall" />
      <Footer parentClass="black" />
    </>
  ) : (
    <>
      <ul className="illumobile">
        {PatternsIllustrations.map((image, index) => (
          <li key={index}>
            <img loading="lazy" src={image} alt="" className={`anim${index}`} />
          </li>
        ))}
      </ul>
      <Footer parentClass="black" />
    </>
  )
}

export default Patterns
