import style from './slideshow.module.scss'
import { useState } from 'react'
import LeftArrow from '../../assets/arrow-left.png'
import RightArrow from '../../assets/arrow-right.png'
import CloseIcon from '../../assets/cross.png'
import Footer from '../Footer/footer'
import LazyLoad from 'react-lazy-load'

function Slideshow({ pictures, parentClass }) {
  const [pictureNumber, updatePicture] = useState(-1)
  const numberOfPic = pictures.length

  function prevPicture() {
    pictureNumber === 0
      ? updatePicture(numberOfPic - 1)
      : updatePicture(pictureNumber - 1)
  }

  function nextPicture() {
    pictureNumber === pictures.length - 1
      ? updatePicture(0)
      : updatePicture(pictureNumber + 1)
  }

  return pictureNumber >= 0 ? (
    <>
      <div className={style.slideshow}>
        <img
          src={LeftArrow}
          className={`${style.slideshow__arrow} ${style.slideshow__arrow__left}`}
          alt="Précédent"
          onClick={prevPicture}
        />
        <img
          className={style.slideshow__picture}
          src={pictures[pictureNumber]}
          alt="Héloïse Weiner illustration"
        />
        <img
          src={RightArrow}
          className={`${style.slideshow__arrow} ${style.slideshow__arrow__right}`}
          alt="Suivant"
          onClick={nextPicture}
        />
        <img
          className={style.slideshow__closeIcon}
          src={CloseIcon}
          alt="Fermer"
          onClick={() => updatePicture(-1)}
        />
      </div>
    </>
  ) : (
    <>
      <ul className={`${style.gallery} ${parentClass}`}>
        {pictures.map((image, index) => (
          <li key={index}>
            <LazyLoad height={363} offsetVertical={200}>
              <img
                loading="lazy"
                className={'slideshow__anim' + index}
                src={image}
                alt=""
                onClick={() => updatePicture(pictures.indexOf(image))}
              />
            </LazyLoad>
          </li>
        ))}
      </ul>
      <Footer parentClass="delay" />
    </>
  )
}

export default Slideshow
