import style from './legal.module.scss'
import Footer from '../../components/Footer/footer'

function Legal() {
  return (
    <>
      <div className={style.page}>
        <h1>Mentions légales</h1>
        <span className={style.content}>
          <h2>Propriétaire du site :</h2>
          <p>Héloïse Weiner</p>
          <p>Activité : Illustratrice indépendante</p>
          <h2>Design et développement :</h2>
          <p>Faustine Brachotte</p>
          <p>61 rue de Lyon - 75012 Paris</p>
          <p>Mail : contact@agilibri.fr</p>
          <h2>Hébergement :</h2>
          <p>Vercel Inc.</p>
          <p>440 N Barranca Ave #4133 Covina, CA 91723</p>
          <p>www.vercel.com</p>
          <p className={style.content__rights}>
            <u>ATTENTION :</u> Les images et illustrations de ce site ne sont
            pas libres de droit, merci de ne pas les utiliser sans
            l’autorisation d'Héloïse Weiner.
          </p>
        </span>
        <Footer parentClass="fixed" />
      </div>
    </>
  )
}

export default Legal
