import { BdIllustrations } from './BdIllustrations'
import Slideshow from '../../components/Slideshow/slideshow'
import Footer from '../../components/Footer/footer'

function BD() {
  return window.innerWidth > 768 ? (
    <>
      <Slideshow pictures={BdIllustrations} parentClass="galleryBD" />
      <Footer parentClass="black" />
    </>
  ) : (
    <>
      <ul className="illumobile">
        {BdIllustrations.map((image, index) => (
          <li key={index}>
            <img loading="lazy" src={image} alt="" />
          </li>
        ))}
      </ul>

      <Footer parentClass="black" />
    </>
  )
}

export default BD
