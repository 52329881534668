import './gallery.css'
import style from '../Slideshow/slideshow.module.scss'
import { useState } from 'react'
import LeftArrow from '../../assets/arrow-left.png'
import RightArrow from '../../assets/arrow-right.png'
import CloseIcon from '../../assets/cross.png'
import { KidsIllustrations } from '../../pages/Kids/kidsIllustrations'

function Gallery() {
  const [pictureNumber, updatePicture] = useState(-1)
  const numberOfPic = 21

  function prevPicture() {
    pictureNumber === 0
      ? updatePicture(numberOfPic - 1)
      : updatePicture(pictureNumber - 1)
  }

  function nextPicture() {
    pictureNumber === 20 ? updatePicture(0) : updatePicture(pictureNumber + 1)
  }

  return pictureNumber >= 0 ? (
    <>
      <div className={style.slideshow}>
        <img
          src={LeftArrow}
          className={`${style.slideshow__arrow} ${style.slideshow__arrow__left}`}
          alt="Précédent"
          onClick={prevPicture}
        />
        <img
          className={style.slideshow__picture}
          src={KidsIllustrations[pictureNumber]}
          alt="Héloïse Weiner illustration"
        />
        <img
          src={RightArrow}
          className={`${style.slideshow__arrow} ${style.slideshow__arrow__right}`}
          alt="Suivant"
          onClick={nextPicture}
        />
        <img
          className={style.slideshow__closeIcon}
          src={CloseIcon}
          alt="Fermer"
          onClick={() => updatePicture(-1)}
        />
      </div>
    </>
  ) : (
    <>
      <div className="album">
        <div className="responsive-container-block bg">
          <div className="responsive-container-block img-cont">
            <img
              className="img slideshow__anim0"
              src={KidsIllustrations[0]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(0)}
            />
            <img
              className="img slideshow__anim3"
              src={KidsIllustrations[3]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(3)}
            />
            <img
              className="img slideshow__anim6"
              src={KidsIllustrations[6]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(6)}
            />
            <img
              className="img slideshow__anim9"
              src={KidsIllustrations[8]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(8)}
            />
            <img
              className="img slideshow__anim12"
              src={KidsIllustrations[10]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(10)}
            />
            <img
              className="img img slideshow__anim15"
              src={KidsIllustrations[14]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(14)}
            />
            <img
              className="img img-last img slideshow__anim18"
              src={KidsIllustrations[16]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(16)}
            />
          </div>
          <div className="responsive-container-block img-cont">
            <img
              className="img img-big slideshow__anim1"
              src={KidsIllustrations[1]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(1)}
            />
            <img
              className="img img-big slideshow__anim4"
              src={KidsIllustrations[4]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(4)}
            />
            <img
              className="img img-big slideshow__anim8"
              src={KidsIllustrations[9]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(9)}
            />
            <img
              className="img img-big slideshow__anim11"
              src={KidsIllustrations[11]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(11)}
            />
            <img
              className="img img-big slideshow__anim13"
              src={KidsIllustrations[15]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(15)}
            />
            <img
              className="img img-big img slideshow__anim16"
              src={KidsIllustrations[17]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(17)}
            />
            <img
              className="img img-big img-last img slideshow__anim19"
              src={KidsIllustrations[19]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(19)}
            />
          </div>
          <div className="responsive-container-block img-cont">
            <img
              className="img slideshow__anim2"
              src={KidsIllustrations[2]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(2)}
            />
            <img
              className="img slideshow__anim5"
              src={KidsIllustrations[5]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(5)}
            />
            <img
              className="img slideshow__anim7"
              src={KidsIllustrations[7]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(7)}
            />
            <img
              className="img slideshow__anim10"
              src={KidsIllustrations[12]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(12)}
            />
            <img
              className="img slideshow__anim14"
              src={KidsIllustrations[13]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(13)}
            />
            <img
              className="img img slideshow__anim17"
              src={KidsIllustrations[18]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(18)}
            />
            <img
              className="img img slideshow__anim20"
              src={KidsIllustrations[20]}
              alt="Héloïse Weiner Illustration"
              onClick={() => updatePicture(20)}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Gallery
