import style from './clients.module.scss'
import { clientsData } from './clientsData'
import Footer from '../../components/Footer/footer'

function Clients() {
  return (
    <>
      <div className={style.page}>
        <div className={style.clients}>
          {clientsData.map((image, index) => (
            <img
              key={index}
              loading="lazy"
              src={image}
              alt=""
              className={'slideshow__anim' + index}
            />
          ))}
        </div>
      </div>
      <Footer parentClass="fixed" />
    </>
  )
}

export default Clients
