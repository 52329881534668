import { Link } from 'react-router-dom'
import aboutMe from '../../assets/Aboutme.png'
import style from './thankyou.module.scss'
import Footer from '../../components/Footer/footer'

function Thankyou() {
  return (
    <>
      <div className={style.page}>
        <h1>Merci !</h1>
        <p className={style.text}>
          Votre demande a bien été envoyée, je vous répondrai très vite !
        </p>
        <img src={aboutMe} alt="Héloïse Weiner illustration" />
        <Link to={`/`} className={style.link}>
          Retourner sur la page d’accueil
        </Link>
        <Footer parentClass="fixed" />
      </div>
    </>
  )
}

export default Thankyou
