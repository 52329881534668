import style from './slideshowOrder.module.scss'
import { useState } from 'react'
import LeftArrow from '../../assets/arrow-left.png'
import RightArrow from '../../assets/arrow-right.png'
import CloseIcon from '../../assets/cross.png'

function SlideshowOrder({ pictures }) {
  const [pictureNumber, updatePicture] = useState(-1)
  const numberOfPic = pictures.length

  function prevPicture() {
    pictureNumber === 0
      ? updatePicture(numberOfPic - 1)
      : updatePicture(pictureNumber - 1)
  }

  function nextPicture() {
    pictureNumber === pictures.length - 1
      ? updatePicture(0)
      : updatePicture(pictureNumber + 1)
  }

  return pictureNumber >= 0 ? (
    <>
      <div className={style.slideshow}>
        <img
          src={LeftArrow}
          className={`${style.slideshow__arrow} ${style.slideshow__arrow__left}`}
          alt="Précédent"
          onClick={prevPicture}
        />
        <img
          className={style.slideshow__picture}
          src={pictures[pictureNumber]}
          alt="Héloïse Weiner illustration"
        />
        <img
          src={RightArrow}
          className={`${style.slideshow__arrow} ${style.slideshow__arrow__right}`}
          alt="Suivant"
          onClick={nextPicture}
        />
        <img
          className={style.slideshow__closeIcon}
          src={CloseIcon}
          alt="Fermer"
          onClick={() => updatePicture(-1)}
        />
      </div>
    </>
  ) : (
    <>
      <ul className={style.gallery}>
        {pictures.map((image, index) => (
          <li key={index}>
            <img
              className={style.anim_order}
              src={image}
              alt=""
              onClick={() => updatePicture(pictures.indexOf(image))}
            />
          </li>
        ))}
      </ul>
    </>
  )
}

export default SlideshowOrder
