import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Navbar from './components/Navbar/navbar'
import Illustrations from './pages/Illustrations/illustrations'
import BD from './pages/BD/Bd'
import Patterns from './pages/Patterns/patterns'
import About from './pages/About/about'
import Clients from './pages/Clients/clients'
import Contact from './pages/Contact/contact'
import Order2 from './pages/Order2/order2'
import OrderBD from './pages/OrderBD/orderBD'
import Thankyou from './pages/Thankyou/thankyou'
import Error from './pages/Error/error'
import Legal from './pages/Legal/legal'
import Test from './pages/Test/test'
import './style/main.scss'
import Slideshow from './components/Slideshow/slideshow'
import { disableReactDevTools } from '@fvilers/disable-react-devtools'
import NavbarDesktop from './components/NavbarDesktop/navbarDesktop'
import Gallery from './components/Gallery/gallery'
import Kids from './pages/Kids/kids'

if (process.env.NODE_ENV === 'production') disableReactDevTools()

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <Router>
      <Navbar />
      <NavbarDesktop />
      <Routes>
        <Route path="/" element={<Illustrations />} />
        <Route path="/bandes-dessinees" element={<BD />} />
        <Route path="/illustrations-jeunesse" element={<Kids />} />
        <Route path="/illustrations-jeunesse/:id" element={<Slideshow />} />
        <Route path="/motifs" element={<Patterns />} />
        <Route path="/a-propos" element={<About />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/commande-fete-des-meres" element={<Order2 />} />
        <Route path="/commande-les-ptits-loulz-2" element={<OrderBD />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Error />} />
        <Route path="/test" element={<Test />} />
        <Route path="/merci" element={<Thankyou />} />
        <Route path="/mentions-legales" element={<Legal />} />
        <Route path="/faust" element={<Gallery />} />
      </Routes>
    </Router>
  </React.StrictMode>
)
