import image00 from '../../assets/Illustrations/Kids/kids-00.jpg'
import image01 from '../../assets/Illustrations/Kids/kids-01.jpg'
import image02 from '../../assets/Illustrations/Kids/kids-02.jpg'
import image03 from '../../assets/Illustrations/Kids/kids-03.jpg'
import image04 from '../../assets/Illustrations/Kids/kids-04.jpg'
import image05 from '../../assets/Illustrations/Kids/kids-05.jpg'
import image06 from '../../assets/Illustrations/Kids/kids-06.jpg'
import image07 from '../../assets/Illustrations/Kids/kids-07.jpeg'
import image08 from '../../assets/Illustrations/Kids/kids-08.jpeg'
import image09 from '../../assets/Illustrations/Kids/kids-09.jpg'
import image10 from '../../assets/Illustrations/Kids/kids-10.jfif'
import image11 from '../../assets/Illustrations/Kids/kids-11.jpg'
import image12 from '../../assets/Illustrations/Kids/kids-12.jpg'
import image13 from '../../assets/Illustrations/Kids/kids-13.jpg'
import image14 from '../../assets/Illustrations/Kids/kids-14.jpg'
import image15 from '../../assets/Illustrations/Kids/kids-15.jpg'
import image16 from '../../assets/Illustrations/Kids/kids-16.jpg'
import image17 from '../../assets/Illustrations/Kids/kids-17.jpg'
import image18 from '../../assets/Illustrations/Kids/kids-18.jpg'
import image19 from '../../assets/Illustrations/Kids/kids-19.jpg'
import image20 from '../../assets/Illustrations/Kids/kids-20.jpg'

export const KidsIllustrations = [
  image00,
  image01,
  image02,
  image03,
  image04,
  image05,
  image06,
  image07,
  image08,
  image09,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
]
