import MenuItem from '@mui/material/MenuItem'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import {
  usePopupState,
  bindHover,
  bindPopover,
} from 'material-ui-popup-state/hooks'
import { NavLink } from 'react-router-dom'
import style from './navbarDesktop.module.scss'
import { Link } from 'react-router-dom'
import AnimatedLogo from '../AnimatedLogo/animatedLogo'
import { useState } from 'react'
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router-dom'

function NavbarDesktop() {
  const location = useLocation()

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  })

  const popupState2 = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  })

  const [isAnimating, setIsAnimating] = useState(false)

  const handleButtonClick = () => {
    setIsAnimating(true)
  }

  const handleAnimationEnd = () => {
    setIsAnimating(false)
  }

  const handleClick = () => {
    popupState.close()
    handleButtonClick()
  }

  const handleClick2 = () => {
    popupState2.close()
    handleButtonClick()
  }

  return (
    <span className={style.navbar}>
      <span>
        <a
          href="https://www.facebook.com/byheloiseweiner"
          target="_blank"
          rel="noreferrer"
          aria-label="Logo Instagram"
        >
          <FontAwesomeIcon
            icon={faFacebookF}
            className={`${style.social} ${style.social__fb}`}
          />
        </a>
        <a
          href="https://www.instagram.com/byheloiseweiner/?hl=fr"
          target="_blank"
          rel="noreferrer"
          aria-label="Logo Instagram"
        >
          <FontAwesomeIcon
            icon={faInstagram}
            className={`${style.social} ${style.social__insta}`}
          />
        </a>
      </span>
      <span className={style.content}>
        <Link className={style.homelink} to={`/`}>
          <span className={style.title} onClick={handleClick}>
            <AnimatedLogo
              isAnimating={isAnimating}
              onAnimationEnd={handleAnimationEnd}
            />{' '}
          </span>
        </Link>
        <nav className={style.nav}>
          <NavLink
            to="/illustrations-jeunesse"
            className={
              location.pathname === '/bandes-dessinees' ||
              location.pathname === '/motifs' ||
              location.pathname === '/illustrations-jeunesse'
                ? 'activeLink'
                : 'nounderline'
            }
          >
            <MenuItem
              className="menu-item"
              {...bindHover(popupState)}
              onClick={handleButtonClick}
            >
              Illustrations
            </MenuItem>
          </NavLink>
          <HoverPopover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            className="popover"
          >
            <NavLink
              to="/illustrations-jeunesse"
              className={
                location.pathname === '/illustrations-jeunesse'
                  ? 'activeLink'
                  : 'nounderline'
              }
            >
              <MenuItem onClick={handleClick}>Jeunesse</MenuItem>
            </NavLink>
            <NavLink
              to="/bandes-dessinees"
              className={
                location.pathname === '/bandes-dessinees'
                  ? 'activeLink'
                  : 'nounderline'
              }
            >
              <MenuItem onClick={handleClick}>Bande dessinées</MenuItem>
            </NavLink>
            <NavLink
              to="/motifs"
              className={
                location.pathname === '/motifs' ? 'activeLink' : 'nounderline'
              }
            >
              <MenuItem onClick={handleClick}>Motifs</MenuItem>
            </NavLink>
          </HoverPopover>

          <NavLink
            to="/clients"
            className="menu-subitem p5"
            style={({ isActive }) => ({
              color: isActive ? 'purple' : 'black',
              textDecoration: isActive ? 'underline' : 'none',
            })}
            onClick={handleClick}
          >
            Clients
          </NavLink>
          <NavLink
            to="/a-propos"
            className="menu-subitem"
            style={({ isActive }) => ({
              color: isActive ? 'purple' : 'black',
              textDecoration: isActive ? 'underline' : 'none',
            })}
            onClick={handleClick}
          >
            À propos
          </NavLink>
          <NavLink
            to="/commande-les-ptits-loulz-2"
            className={
              location.pathname === '/commande-fete-des-meres' ||
              location.pathname === '/commande-les-ptits-loulz-2'
                ? 'activeLink'
                : 'nounderline'
            }
          >
            <MenuItem
              className="menu-item"
              {...bindHover(popupState2)}
              onClick={handleClick}
            >
              Commander
            </MenuItem>
          </NavLink>
          <HoverPopover
            {...bindPopover(popupState2)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            className="popover"
          >
            <NavLink
              to="/commande-les-ptits-loulz-2"
              className={
                location.pathname === '/commande-les-ptits-loulz-2'
                  ? 'activeLink'
                  : 'nounderline'
              }
            >
              <MenuItem onClick={handleClick2}>Les Ptit's Loul'z 2</MenuItem>
            </NavLink>
            <NavLink
              to="/commande-fete-des-meres"
              className={
                location.pathname === '/commande-fete-des-meres'
                  ? 'activeLink'
                  : 'nounderline'
              }
            >
              <MenuItem onClick={handleClick2}>
                Un portrait personnalisé
              </MenuItem>
            </NavLink>
          </HoverPopover>

          <NavLink
            to="/contact"
            className="menu-subitem"
            style={({ isActive }) => ({
              color: isActive ? 'purple' : 'black',
              textDecoration: isActive ? 'underline' : 'none',
            })}
            onClick={handleClick}
          >
            Contact
          </NavLink>
        </nav>
      </span>
    </span>
  )
}

export default NavbarDesktop
