import style from './orderBD.module.scss'
import FormImg1 from '../../assets/form-1.png'
import FormImg2 from '../../assets/form-2.png'
import FormImg3 from '../../assets/form-3.png'
import bgimg from '../../assets/blue.png'
import Footer from '../../components/Footer/footer'
import SlideshowOrder from '../../components/SlideshowOrder/slideshowOrder'
import SlideshowReviews from '../../components/SlideshowReviews/slideshowReviews'
import { Loulz2Illu } from './Loulz2Illu'
import { useNavigate } from 'react-router-dom'
import { useRef, useEffect } from 'react'
import emailjs from '@emailjs/browser'
import { Loulz2ViewsDesktop } from './Loulz2viewsDesktop'
import { Loulz2Views } from './Loulz2views'
import stars from '../../assets/stars.png'

function Order2() {
  ;(function () {
    emailjs.init(process.env.REACT_APP_EMAIL_PUBLIC_KEY_BD2)
  })()
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault()
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID_BD2,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID_BD2,
        form.current,
        process.env.REACT_APP_EMAIL_PUBLIC_KEY_BD2
      )
      .then(
        (result) => {
          handleRedirect()
        },
        (error) => {
          alert("Un problème a été rencontré lors de l'envoi du formulaire.")
        }
      )
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const navigate = useNavigate()

  function handleRedirect() {
    navigate('/merci')
  }

  return (
    <>
      <div className={style.page}>
        <h1>Commandez la bande dessinée Les P'tits Loul'z 2 !</h1>
        {window.innerWidth > 768 ? (
          <SlideshowOrder pictures={Loulz2Illu} />
        ) : (
          <>
            <ul className={style.loulzillu}>
              {Loulz2Illu.map((image, index) => (
                <li key={index}>
                  <img loading="lazy" src={image} alt="" />
                </li>
              ))}
            </ul>
          </>
        )}

        <div className={style.content}>
          <span className={style.content__text}>
            <p>
              Plongez dans les tribulations d'une famille nombreuse
              bienveillante et dépassée. Entre mots d'enfants mignons à souhait,
              négociations et conspirations anti parentales, vous n'êtes pas à
              l'abri de vous reconnaître dans ces pages. Une BD légère et drôle
              pour toute la famille.
            </p>
            <p>
              Impression sur papier épais de qualité par un imprimeur français
              certifié PEFC.
            </p>
            <p>
              <strong>En cadeau :</strong> un goodie aléatoire (carte postale,
              pin's, magnet, marque page)
            </p>
            <p>
              <strong>Prix avec livraison :</strong> 18,50€
            </p>
            <p>
              Livraison en France uniquement. Le paiement se fait par virement.
            </p>
          </span>
          <form ref={form} className={style.contactForm} onSubmit={sendEmail}>
            <span className="form-icons">
              <img
                className={`${style.pen} ${style.pen__mobile}`}
                src={FormImg1}
                alt=""
              ></img>
              <input
                name="name"
                type="text"
                className={`${style.contactForm__global} ${style.contactForm__name}`}
                placeholder="Prénom et nom"
                required
              />
            </span>
            <span className="form-icons">
              <img
                className={`${style.pen} ${style.pen__mobile}`}
                src={FormImg2}
                alt=""
              ></img>
              <input
                name="email"
                type="email"
                className={`${style.contactForm__global} ${style.contactForm__email}`}
                placeholder="Email"
                required
              />
            </span>
            <span className="form-icons">
              <img
                className={`${style.pen} ${style.pen__mobile}`}
                src={FormImg3}
                alt=""
              ></img>
              <input
                name="adress"
                type="text"
                className={`${style.contactForm__global} ${style.contactForm__title}`}
                placeholder="Adresse de livraison"
                required
              />
            </span>
            <span className="form-icons">
              <input
                name="postcode"
                type="text"
                className={`${style.contactForm__global} ${style.contactForm__title} ${style.contactForm__postcode}`}
                placeholder="Code postal et ville"
                required
              />
            </span>

            <span className={style.contactForm__bgimg}>
              <img src={bgimg} alt=""></img>
              <input
                className={`${style.contactForm__global} ${style.contactForm__submit}`}
                type="submit"
                value="COMMANDER LES P'TITS LOUL'Z 2"
              />
            </span>
          </form>
        </div>
        <span className={style.reviewsTitle}>
          <h2>Vos retours</h2>
          <img className={style.star} id="star" src={stars} alt="" />
        </span>
        {window.innerWidth > 768 ? (
          <SlideshowReviews pictures={Loulz2ViewsDesktop} />
        ) : (
          <>
            <ul className={style.gallery}>
              {Loulz2Views.map((image, index) => (
                <li key={index}>
                  <img loading="lazy" src={image} alt="" />
                </li>
              ))}
            </ul>
          </>
        )}
      </div>

      <Footer parentClass="black" />
    </>
  )
}

export default Order2
