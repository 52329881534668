import AnimatedLogo from '../../components/AnimatedLogo/animatedLogo'
import { useState } from 'react'

const Test = () => {
  const [isAnimating, setIsAnimating] = useState(false)

  const handleButtonClick = () => {
    setIsAnimating(true)
  }

  const handleAnimationEnd = () => {
    setIsAnimating(false)
  }

  return (
    <div>
      <button onClick={handleButtonClick}>Start Animation</button>
      <AnimatedLogo
        isAnimating={isAnimating}
        onAnimationEnd={handleAnimationEnd}
      />
    </div>
  )
}

export default Test
