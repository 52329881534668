import React, { useState, useEffect } from 'react'
import logo1 from '../../assets/logos/logo1.png'
import logo2 from '../../assets/logos/logo2.png'
import logo3 from '../../assets/logos/logo3.png'
import logo4 from '../../assets/logos/logo4.png'
import logo5 from '../../assets/logos/logo5.png'
import logo6 from '../../assets/logos/logo6.png'
import logo7 from '../../assets/logos/logo7.png'
import logo8 from '../../assets/logos/logo8.png'
import logo9 from '../../assets/logos/logo1.png'
import './AnimatedLogo.css'

const logos = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9]

const AnimatedLogo = ({ isAnimating, onAnimationEnd }) => {
  const [currentLogoIndex, setCurrentLogoIndex] = useState(0)

  useEffect(() => {
    if (isAnimating) {
      setCurrentLogoIndex(0) // Reset to the first logo
      const interval = setInterval(() => {
        setCurrentLogoIndex((prevIndex) => {
          if (prevIndex + 1 === logos.length) {
            clearInterval(interval)
            setTimeout(onAnimationEnd, 0) // Defer the call to onAnimationEnd
            return prevIndex
          }
          return prevIndex + 1
        })
      }, 50) // Change image every 100 milliseconds
      return () => clearInterval(interval)
    }
  }, [isAnimating, onAnimationEnd])

  return (
    <img src={logos[currentLogoIndex]} alt="Logo" className="animated-logo" />
  )
}

export default AnimatedLogo
