import { Link } from 'react-router-dom'
import { navbarDataIllustrations } from './navbarDataIllustrations'
import { navbarDataOrder } from './navbarDataOrder'
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import logo from '../../assets/logo.png'
import style from './navbar.module.scss'
import { NavLink } from 'react-router-dom'

function Navbar() {
  function closeBurger() {
    handleShowLinks()
    starAnimation()
  }

  const [showLinks, setShowLinks] = useState(false)
  const handleShowLinks = () => {
    setShowLinks(!showLinks)
  }

  function starAnimation() {
    document.getElementById('star').classList.toggle('animation')
  }

  return (
    <span className={style.navbar}>
      <span className={style.content}>
        <Link
          className={style.homelink}
          to={`/`}
          onClick={() => setShowLinks(false)}
        >
          <span className={style.title}>
            <img className={style.logo} id="star" src={logo} alt="" />
          </span>
        </Link>
        <nav className={` ${showLinks ? 'show-nav' : 'hide-nav'}`}>
          <ul className="navbar__links">
            <ul>
              <NavLink to="/illustrations-jeunesse" onClick={closeBurger}>
                Illustrations
              </NavLink>
            </ul>
            {navbarDataIllustrations.map((item, index) => {
              return (
                <li key={index} className="navbar__item">
                  <NavLink
                    to={item.path}
                    style={({ isActive }) => ({
                      color: isActive ? 'purple' : 'black',
                      textDecoration: isActive ? 'underline' : 'none',
                    })}
                    className="navbar__link"
                    onClick={closeBurger}
                  >
                    <span>{item.title}</span>
                  </NavLink>
                </li>
              )
            })}
            <ul className="ul_bar">
              <NavLink to="/commande-les-ptits-loulz-2" onClick={closeBurger}>
                Commander
              </NavLink>
            </ul>

            {navbarDataOrder.map((item, index) => {
              return (
                <li key={index} className="navbar__item">
                  <NavLink
                    to={item.path}
                    style={({ isActive }) => ({
                      color: isActive ? 'purple' : 'black',
                      textDecoration: isActive ? 'underline' : 'none',
                    })}
                    className="navbar__link"
                    onClick={closeBurger}
                  >
                    <span>{item.title}</span>
                  </NavLink>
                </li>
              )
            })}
            <p
              className={`'navbar__item' ${style.item_contact} ${style.item_about}`}
            >
              <NavLink
                to="/a-propos"
                style={({ isActive }) => ({
                  color: isActive ? 'purple' : 'black',
                  textDecoration: isActive ? 'underline' : 'none',
                })}
                className="navbar__link"
                onClick={closeBurger}
              >
                <span>À propos</span>
              </NavLink>
            </p>
            <p className={`'navbar__item' ${style.item_contact}`}>
              <NavLink
                to="/clients"
                style={({ isActive }) => ({
                  color: isActive ? 'purple' : 'black',
                  textDecoration: isActive ? 'underline' : 'none',
                })}
                className="navbar__link"
                onClick={closeBurger}
              >
                <span>Clients</span>
              </NavLink>
            </p>
            <p className={`'navbar__item' ${style.item_contact}`}>
              <NavLink
                to="/contact"
                style={({ isActive }) => ({
                  color: isActive ? 'purple' : 'black',
                  textDecoration: isActive ? 'underline' : 'none',
                })}
                className="navbar__link"
                onClick={closeBurger}
              >
                <span>Contact</span>
              </NavLink>
            </p>
            <span className={style.social}>
              <a
                href="https://www.facebook.com/byheloiseweiner"
                target="_blank"
                rel="noreferrer"
                aria-label="Logo Instagram"
              >
                <FontAwesomeIcon icon={faFacebookF} className={style.social} />
              </a>
              <a
                href="https://www.instagram.com/byheloiseweiner/?hl=fr"
                target="_blank"
                rel="noreferrer"
                aria-label="Logo Instagram"
              >
                <FontAwesomeIcon icon={faInstagram} className={style.social} />
              </a>
            </span>
          </ul>
          <button
            className="navbar__burger anim"
            onClick={closeBurger}
            aria-label="Menu"
          >
            <span className="burger-bar"></span>
          </button>
        </nav>
      </span>
    </span>
  )
}

export default Navbar
