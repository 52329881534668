import style from './contact.module.scss'
import ContactImg from '../../assets/ContactImg.png'
import Footer from '../../components/Footer/footer'

function Contact() {
  return (
    <>
      <div className={style.page}>
        <img src={ContactImg} alt="Héloïse Weiner illustration"></img>
        <div className={style.side}>
          <p>Je serais ravie de travailler avec vous sur un nouveau projet !</p>
          <p>
            Envoyez-moi un mail à{' '}
            <a href="mailto:byheloiseweiner@gmail.com">
              byheloiseweiner@gmail.com
            </a>{' '}
            ou via mon compte{' '}
            <a
              href="https://www.instagram.com/byheloiseweiner/?hl=fr"
              target="_blank"
              rel="noreferrer"
              aria-label="Logo Instagram"
            >
              Instagram
            </a>{' '}
            et je vous répondrai rapidement.
          </p>
          <p>À bientôt,</p>
          <p>Héloïse</p>
        </div>
      </div>
      <Footer parentClass="fixed" />
    </>
  )
}

export default Contact
