export const navbarDataOrder = [
  {
    title: "Les P'tits Loul'z 2",
    path: '/commande-les-ptits-loulz-2',
  },
  {
    title: 'Un portrait personnalisé',
    path: '/commande-fete-des-meres',
  },
]
