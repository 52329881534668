import { KidsIllustrations } from './kidsIllustrations'
import Footer from '../../components/Footer/footer'
import Gallery from '../../components/Gallery/gallery'

function Kids() {
  return window.innerWidth > 768 ? (
    <>
      <Gallery />
      <Footer parentClass="black" />
    </>
  ) : (
    <>
      <div className="album">
        <div className="responsive-container-block bg">
          <div className="responsive-container-block img-cont">
            <img
              className="img slideshow__anim0"
              src={KidsIllustrations[0]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img slideshow__anim3"
              src={KidsIllustrations[3]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img slideshow__anim6"
              src={KidsIllustrations[6]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img slideshow__anim9"
              src={KidsIllustrations[8]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img slideshow__anim12"
              src={KidsIllustrations[10]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img img slideshow__anim15"
              src={KidsIllustrations[14]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img img-last img slideshow__anim18"
              src={KidsIllustrations[16]}
              alt="Héloïse Weiner Illustration"
            />
          </div>
          <div className="responsive-container-block img-cont">
            <img
              className="img img-big slideshow__anim1"
              src={KidsIllustrations[1]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img img-big slideshow__anim4"
              src={KidsIllustrations[4]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img img-big slideshow__anim8"
              src={KidsIllustrations[9]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img img-big slideshow__anim11"
              src={KidsIllustrations[11]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img img-big slideshow__anim13"
              src={KidsIllustrations[15]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img img-big img slideshow__anim16"
              src={KidsIllustrations[17]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img img-big img-last img slideshow__anim19"
              src={KidsIllustrations[19]}
              alt="Héloïse Weiner Illustration"
            />
          </div>
          <div className="responsive-container-block img-cont">
            <img
              className="img slideshow__anim2"
              src={KidsIllustrations[2]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img slideshow__anim5"
              src={KidsIllustrations[5]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img slideshow__anim7"
              src={KidsIllustrations[7]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img slideshow__anim10"
              src={KidsIllustrations[12]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img slideshow__anim14"
              src={KidsIllustrations[13]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img img slideshow__anim17"
              src={KidsIllustrations[18]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img img slideshow__anim20"
              src={KidsIllustrations[20]}
              alt="Héloïse Weiner Illustration"
            />
          </div>
        </div>
      </div>
      <Footer parentClass="black" />
    </>
  )
}

export default Kids
