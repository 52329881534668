import image00 from '../../assets/Illustrations/BD/Bd-000.jfif'
import image02 from '../../assets/Illustrations/BD/Bd-002.jfif'
import image04 from '../../assets/Illustrations/BD/Bd-004.jfif'
import image05 from '../../assets/Illustrations/BD/Bd-005.jfif'
import image06 from '../../assets/Illustrations/BD/Bd-006.jfif'
import image07 from '../../assets/Illustrations/BD/Bd-007.jfif'
import image08 from '../../assets/Illustrations/BD/Bd-008.jfif'
import image12 from '../../assets/Illustrations/BD/Bd-012.jpg'

export const BdIllustrations = [
  image00,
  image02,
  image04,
  image05,
  image08,
  image12,
  image06,
  image07,
]
