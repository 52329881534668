export const navbarDataIllustrations = [
  {
    title: 'Jeunesse',
    path: '/illustrations-jeunesse',
  },
  {
    title: 'Bandes dessinées',
    path: '/bandes-dessinees',
  },
  {
    title: 'Motifs',
    path: '/motifs',
  },
]
