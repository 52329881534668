import { Link } from 'react-router-dom'
import style from './error.module.scss'
import errorImg from '../../assets/tea.png'
import Footer from '../../components/Footer/footer'

function Error() {
  return (
    <>
      <div className={style.error}>
        <img src={errorImg} alt="Héloïse Weiner illustration" />
        <p>Oups ! La page que vous demandez n'existe pas.</p>
        <Link to={`/`}>Retourner sur la page d’accueil</Link>
      </div>
      <Footer parentClass="fixed" />
    </>
  )
}

export default Error
