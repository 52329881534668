import image00 from '../../assets/Illustrations/Top6/00.jpg'
import image01 from '../../assets/Illustrations/Top6/01.jpg'
import image02 from '../../assets/Illustrations/Top6/02.jpg'
import image03 from '../../assets/Illustrations/Top6/03.jpg'
import image04 from '../../assets/Illustrations/Top6/04.jpg'
import image05 from '../../assets/Illustrations/Top6/05.jpg'
import image06 from '../../assets/Illustrations/Top6/06.jpg'

export const illustrationsData = [
  image00,
  image01,
  image02,
  image03,
  image04,
  image05,
  image06,
]
