import image00 from '../../assets/Illustrations/OrderBD/view00.png'
import image01 from '../../assets/Illustrations/OrderBD/view01.png'
import image02 from '../../assets/Illustrations/OrderBD/view02.png'
import image03 from '../../assets/Illustrations/OrderBD/view03.png'
import image04 from '../../assets/Illustrations/OrderBD/view04.png'
import image05 from '../../assets/Illustrations/OrderBD/view05.png'
import image06 from '../../assets/Illustrations/OrderBD/view06.png'
import image07 from '../../assets/Illustrations/OrderBD/view07.png'

export const Loulz2Views = [
  image00,
  image01,
  image02,
  image03,
  image04,
  image05,
  image06,
  image07,
]
