import style from './slideshowReviews.module.scss'
import { useState } from 'react'
import LeftArrow from '../../assets/arrow-left.png'
import RightArrow from '../../assets/arrow-right.png'

function SlideshowReviews({ pictures }) {
  const [pictureNumber, updatePicture] = useState(0)
  const numberOfPic = pictures.length

  function prevPicture() {
    pictureNumber === 0
      ? updatePicture(numberOfPic - 1)
      : updatePicture(pictureNumber - 1)
  }

  function nextPicture() {
    pictureNumber === pictures.length - 1
      ? updatePicture(0)
      : updatePicture(pictureNumber + 1)
  }

  return (
    <>
      <div className={style.slideshow}>
        {pictureNumber > 0 && (
          <img
            src={LeftArrow}
            className={`${style.slideshow__arrow} ${style.slideshow__arrow__left}`}
            alt="Précédent"
            onClick={prevPicture}
          />
        )}
        <img
          className={style.slideshow__picture}
          src={pictures[pictureNumber]}
          alt="Avis Les Ptit's Loul'z 2"
        />
        {pictureNumber < 2 && (
          <img
            src={RightArrow}
            className={`${style.slideshow__arrow} ${style.slideshow__arrow__right}`}
            alt="Suivant"
            onClick={nextPicture}
          />
        )}
      </div>
    </>
  )
}

export default SlideshowReviews
