import { illustrationsData } from './illustrationsData'
import Footer from '../../components/Footer/footer'
import GalleryTop6 from '../../components/GalleryTop6/gallerytop6'

function Illustrations() {
  return window.innerWidth > 768 ? (
    <>
      <GalleryTop6 />
      <Footer parentClass="black" />
    </>
  ) : (
    <>
      <div className="album">
        <div className="responsive-container-block bg">
          <div className="responsive-container-block img-cont">
            <img
              className="img slideshow__anim0"
              src={illustrationsData[2]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img slideshow__anim1"
              src={illustrationsData[0]}
              alt="Héloïse Weiner Illustration"
            />
          </div>
          <div className="responsive-container-block img-cont">
            <img
              className="img slideshow__anim2"
              src={illustrationsData[3]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img slideshow__anim3"
              src={illustrationsData[1]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img img-last slideshow__anim4"
              src={illustrationsData[4]}
              alt="Héloïse Weiner Illustration"
            />
          </div>
          <div className="responsive-container-block img-cont">
            <img
              className="img slideshow__anim5"
              src={illustrationsData[6]}
              alt="Héloïse Weiner Illustration"
            />
            <img
              className="img slideshow__anim6"
              src={illustrationsData[5]}
              alt="Héloïse Weiner Illustration"
            />
          </div>
        </div>
      </div>
      <Footer parentClass="black" />
    </>
  )
}

export default Illustrations
