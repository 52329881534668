import me from '../../assets/me.png'

import style from './about.module.scss'
import Footer from '../../components/Footer/footer'

function About() {
  return (
    <>
      <div className={style.page}>
        <img src={me} alt="Héloïse Weiner illustration" />

        <div className={style.content}>
          <p>
            De nature créative et passionnée par l'éducation des enfants depuis
            toujours, j'ai joins mes deux passions en étant à la fois maîtresse
            et illustratrice.
          </p>
          <p>
            J'aime dessiner les anecdotes de ma propre vie de famille et je rêve
            de voir un jour mes dessins dans un livre jeunesse.
          </p>
        </div>
      </div>
      <Footer parentClass="fixed" />
    </>
  )
}

export default About
