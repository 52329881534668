import style from './order2.module.scss'
import FormImg1 from '../../assets/form-1.png'
import FormImg2 from '../../assets/form-2.png'
import FormImg3 from '../../assets/form-3.png'
import Footer from '../../components/Footer/footer'
import { useNavigate } from 'react-router-dom'
import { useRef, useEffect } from 'react'
import emailjs from '@emailjs/browser'
import SlideshowOrder from '../../components/SlideshowOrder/slideshowOrder'
import { orderIllustrations } from './order2Illustrations'

function Order2() {
  ;(function () {
    emailjs.init(process.env.EMAIL_PUBLIC_KEY)
  })()
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault()
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAIL_PUBLIC_KEY
      )
      .then(
        (result) => {
          handleRedirect()
        },
        (error) => {
          alert("Un problème a été rencontré lors de l'envoi du formulaire.")
        }
      )
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const navigate = useNavigate()

  function handleRedirect() {
    navigate('/merci')
  }

  return (
    <>
      <div className={style.page}>
        <h1>
          Offrez un cadeau unique pour la fête des mères : un portrait illustré
          personnalisé !
        </h1>
        {window.innerWidth > 768 ? (
          <SlideshowOrder pictures={orderIllustrations} />
        ) : (
          <>
            <ul className={style.loulzillu}>
              {orderIllustrations.map((image, index) => (
                <li key={index}>
                  <img loading="lazy" src={image} alt="" />
                </li>
              ))}
            </ul>
          </>
        )}
        <div className={style.content}>
          <span className={style.content__text}>
            <p>
              Vous choisissez la mise en scène (ou une photo à reproduire). Pas
              de décor, mais un fond de couleur et/ou des bulles de texte
              peuvent être ajoutés sans frais supplémentaires.
            </p>
            <p>
              Les images sont là pour donner une idée, tout est personnalisable
              selon vos souhaits et dans la limite des contraintes décrites.
            </p>
            <p>
              <strong>Prix :</strong> 40€ pour la maman + un enfant, 10€ par
              enfant supplémentaire. Le paiement se fait par virement.
            </p>
            <p>
              <strong>À noter :</strong> l'image est envoyée en pdf haute
              qualité, c'est à vous de vous occuper de l'impression si vous le
              souhaitez (peut être imprimé sur tout type de support : tasse,
              t-shirt, ...)
            </p>
            <p>
              Pour assurer la réception des commandes dans les temps, l'offre
              est limitée aux 5 premières demandes !
            </p>
            <p>Je vous répondrai dans les 48 heures. Merci !</p>

            <p>Héloïse</p>
          </span>
          <form ref={form} className={style.contactForm} onSubmit={sendEmail}>
            <span className="form-icons">
              <img
                className={`${style.pen} ${style.pen__mobile}`}
                src={FormImg1}
                alt=""
              ></img>
              <input
                name="from_name"
                type="text"
                className={`${style.contactForm__global} ${style.contactForm__name}`}
                placeholder="Nom"
                required
              />
            </span>
            <span className="form-icons">
              <img
                className={`${style.pen} ${style.pen__mobile}`}
                src={FormImg2}
                alt=""
              ></img>
              <input
                name="to_name"
                type="email"
                className={`${style.contactForm__global} ${style.contactForm__email}`}
                placeholder="Email"
                required
              />
            </span>
            <span className="form-icons">
              <img
                className={`${style.pen} ${style.pen__mobile}`}
                src={FormImg3}
                alt=""
              ></img>
              <input
                name="title"
                type="text"
                className={`${style.contactForm__global} ${style.contactForm__title}`}
                placeholder="Nombre d'enfants à dessiner"
                required
              />
            </span>
            <textarea
              name="message"
              id="message"
              type="text"
              className={`${style.contactForm__global} ${style.contactForm__message}`}
              placeholder="Description de l'illustration souhaitée"
              required
            />
            <input
              className={`${style.contactForm__global} ${style.contactForm__submit} ${style.contactForm__submit__outofstock}`}
              type="submit"
              value="COMMANDER UN PORTRAIT PERSONNALISÉ"
              disabled
            />
            <p className={style.outofstock}>Les portraits sont épuisés !</p>
          </form>
        </div>
      </div>
      <Footer parentClass="black" />
    </>
  )
}

export default Order2
