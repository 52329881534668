import { Link } from 'react-router-dom'
import style from './footer.module.scss'

function Footer({ parentClass }) {
  function starAnimation() {
    document.getElementById('star').classList.toggle('animation')
  }

  return (
    <footer>
      <p className={`${style.legal} ${style.legal__desktop} ${parentClass}`}>
        © 2024 - Tous droits réservés -{' '}
        <Link to="/mentions-legales" onClick={starAnimation}>
          Mentions légales
        </Link>
      </p>
      <p className={`${style.legal} ${style.legal__mobile} ${parentClass}`}>
        © 2024 - Tous droits réservés -{' '}
        <Link to="/mentions-legales" onClick={starAnimation}>
          Mentions légales
        </Link>
      </p>
    </footer>
  )
}

export default Footer
