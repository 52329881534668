import image00 from '../../assets/Illustrations/Patterns/patterns-000.jpg'
import image01 from '../../assets/Illustrations/Patterns/patterns-001.jpg'
import image02 from '../../assets/Illustrations/Patterns/patterns-002.jpg'
import image03 from '../../assets/Illustrations/Patterns/patterns-003.jpg'
import image04 from '../../assets/Illustrations/Patterns/patterns-004.jpg'
import image05 from '../../assets/Illustrations/Patterns/patterns-005.jpeg'
import image06 from '../../assets/Illustrations/Patterns/patterns-006.jpg'
import image07 from '../../assets/Illustrations/Patterns/patterns-007.jpg'
import image08 from '../../assets/Illustrations/Patterns/patterns-008.jpg'

export const PatternsIllustrations = [
  image00,
  image01,
  image02,
  image03,
  image04,
  image05,
  image06,
  image07,
  image08,
]
